import { Component, OnInit, Inject, DestroyRef, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthGuardService } from '../../services/auth-guard.service';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface Items {
    name: string;
    checked: boolean;
    id: string;
    description: string;
    privacy: string;
}

interface Data {
    components: Items[]
}
@Component({
    selector: 'app-cookie',
    styleUrls: ['./cookie.component.scss'],
    templateUrl: './cookie.component.html'
})
export class CookieComponent implements OnInit {
    mobile: boolean = false;
    openConfigs: boolean = false;
    components: Items[] = [];
    destroyRef = inject(DestroyRef);

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Data,
        private auth: AuthGuardService,
        protected mediaObserver: MediaObserver,
        public snackBarRef: MatDialogRef<CookieComponent>
    ) { }

    ngOnInit(): void {
        this.components = this.data?.components;
        this.initMedia();
    }

    initMedia(): void {
        this.mediaObserver.asObservable().pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((change: MediaChange[]) => {
            this.mobile = change[0].mqAlias === 'sm' || change[0].mqAlias === 'xs';
        });
    }

    async openDatenschutz() {
        window.open('https://' + this.auth.getPartner()?.partnerdomain + '/datenschutz', '_blank');
    }

    async openImpressum() {
        window.open('https://' + this.auth.getPartner()?.partnerdomain + '/impressum', '_blank');
    }

    save(): void {
        this.snackBarRef.close(this.components);
    }

    reject(): void {
        for (let i = 0; i < this.components.length; i++) {
            if (this.components[i].id?.length > 0) {
                this.components[i].checked = false;
            }
        }
        this.snackBarRef.close(this.components);
    }

    allaccept(): void {
        for (let i = 0; i < this.components.length; i++) {
            if (this.components[i].id?.length > 0) {
                this.components[i].checked = true;
            }
        }
        this.snackBarRef.close(this.components);
    }
}
