import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID, TransferState, makeStateKey } from '@angular/core';
import { UrlSerializer, UrlTree, DefaultUrlSerializer } from '@angular/router';
import { USE_SLASH_TOKEN } from 'tockens';

const STATE_KEY_USESLASH = makeStateKey('useSlash');

@Injectable(
    { providedIn: 'root' }
)

export class CustomeUrlSerializer implements UrlSerializer {
    useSlash: string;
    constructor(
        @Inject(PLATFORM_ID) protected platformId: any,
        protected state: TransferState,
        @Optional() @Inject(USE_SLASH_TOKEN) private use_slash: string,
    ) {
        if (isPlatformServer(this.platformId)) {
            this.useSlash = this.use_slash;
            this.state.set(STATE_KEY_USESLASH, <any>this.use_slash);
        } else {
            this.useSlash = this.state.get(STATE_KEY_USESLASH, <any>'');
        }
        // console.log(this.useSlash)
    }

    parse(url: any): UrlTree {
        const dus = new DefaultUrlSerializer();
        return dus.parse(url);
    }

    serialize(tree: UrlTree): any {
        const dus = new DefaultUrlSerializer();
        let path = dus.serialize(tree);
        const pathArr = path.split('?');
        if (!pathArr[0].endsWith('/') && this.useSlash === '1') {
            pathArr[0] += '/';
        }
        const joinSeparator = pathArr.length > 1 ? '?' : '';
        path = pathArr.join(joinSeparator);
        return path;
    }
}
