import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, Inject, InjectionToken, OnDestroy, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { fromEvent, Subject, takeUntil } from 'rxjs';
import { AuthGuardService } from './services/auth-guard.service';
import { environment } from '../environments/environment';

import { CookiesService } from './services/cookies.service';
import { DateService } from './services/date.service';
import { ChatService } from './services/chat.service';
import { Partner } from './models/partner/IPartner';
import { PartnerService } from './services/partner.service';
import { Router } from '@angular/router';
import { MEDIA_TOKEN, PARTNER_TOKEN } from 'tockens';
import { I18nService } from './services/i18n.service';
// import { ScreenService } from './services/screen.service';
import { EncryptionService } from './services/encyption.service';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { TransferState, makeStateKey } from '@angular/core';
// import { SwUpdate } from '@angular/service-worker';
// import { PagerReloadComponent } from './components/pagereload/pagereload.component';
const STATE_KEY_PARTNER = makeStateKey('partnerdata');
@Component({
    selector: 'reisen-online',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    title = '';
    isBrowser: boolean = false;
    showScrollTopButton: boolean = false;
    showchatpanel: boolean = false;
    smallMode: boolean = false;
    showChatAgent: boolean = true;
    showChat: boolean = true;
    displaySite: boolean = true;
    hidenNavbar: boolean = false;
    partnerid: string = '';
    pass: string = '';
    protected secret: string = 'cleveracvis';
    protected curentPartner: Partner;
    protected lastposition: number = 0;
    protected unsubscribe$ = new Subject();

    constructor(
        // protected adapter: DateAdapter<any>,
        protected i18nService: I18nService,
        @Inject(PLATFORM_ID) protected platformId: any,
        @Inject(DOCUMENT) protected document: any,
        protected cookieService: CookiesService,
        protected dateService: DateService,
        protected chatService: ChatService,
        protected authguard: AuthGuardService,
        protected partnerService: PartnerService,
        // protected screenService: ScreenService,
        protected encService: EncryptionService,
        protected router: Router,
        // @Optional() private update: SwUpdate,
        protected cdr: ChangeDetectorRef,
        protected state: TransferState,
        protected mediaObserver: MediaObserver,
        @Optional() @Inject(PARTNER_TOKEN) private partner_id: InjectionToken<string>,
        @Optional() @Inject(MEDIA_TOKEN) private media_token: string
    ) {
        this.isBrowser = isPlatformBrowser(this.platformId);
        this.smallMode = this.media_token === 'xs';
        this.partnerid = this.partner_id?.toString();
        if (!this.partnerid && this.isBrowser) {
            this.partnerid = window.localStorage.getItem('partnerid');
        }
    }

    scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    @HostListener('window:beforeunload', ['$event'])
    ngOnDestroy(): void {
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
    }

    ngOnInit(): void {
        this.getPartner();
        this.checkHidennavbar();
        // if (this.isBrowser && environment.production) {
        //     this.checkUpdate();
        // }
    }

    // async checkUpdate() {
    //     this.update?.checkForUpdate();

    //     this.update?.versionUpdates.pipe(
    //         takeUntil(this.unsubscribe$)
    //     ).subscribe(async evt => {
    //         // console.log('UpdateService: versionUpdates', evt);
    //         switch (evt.type) {
    //             case 'VERSION_DETECTED':
    //                 // console.log(`Downloading new app version: ${evt.version.hash}`);
    //                 break;
    //             case 'VERSION_READY':
    //                 // console.log(`Current app version: ${evt.currentVersion.hash}`);
    //                 // console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
    //                 // eslint-disable-next-line no-undef
    //                 this.updateClient();
    //                 break;
    //             case 'VERSION_INSTALLATION_FAILED':
    //                 // console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
    //                 break;
    //         }
    //     });
    // }

    // updateClient() {
    //     const sheetRef = this.bottomSheet.open(PagerReloadComponent, {
    //         data: 'Update verfügbar, bitte aktualisieren'
    //     });
    //     sheetRef.afterDismissed().pipe(
    //         takeUntil(this.unsubscribe$)
    //     ).subscribe(async data => {
    //         if (data && data.message === 'Abbrechen') {
    //             //
    //         } if (data && data.message === 'Aktualisieren') {
    //             await this.update.activateUpdate();
    //             // eslint-disable-next-line no-undef
    //             location.reload();
    //         }
    //     });
    // }

    async initMedia() {
        this.mediaObserver.asObservable().pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((change: MediaChange[]) => {
            this.smallMode = change[0].mqAlias === 'xs' || change[0].mqAlias === 'sm' || change[0].mqAlias === 'md';
            if (this.isBrowser) {
                this.checkShowSite();
            }
        });
    }

    async checkHidennavbar() {
        this.authguard.gethidenHeaderFooter$.pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((e) => {
            this.hidenNavbar = e;
            this.cdr.detectChanges();
        });
    }

    async getPartner() {
        this.curentPartner = this.state.get(STATE_KEY_PARTNER, <any>{});
        this.state.set(STATE_KEY_PARTNER, <any>{}); // remove content for next loading
        // const start = Date.now();
        if (this.curentPartner?.partnerid?.length > 0) {
            this.showChat = !this.curentPartner.hideChat;
            this.displaySite = !(this.curentPartner.partnersite?.length > 0);
            this.cdr.detectChanges();
            this.initPartnerData();
        } else {
            this.partnerService.request({ partnerid: this.partnerid }, 'checkpartner').pipe(
                takeUntil(this.unsubscribe$)
            ).subscribe((res) => {
                if (res?.data?.length > 0) {
                    const tokenFromUI = res.data?.slice(-16);
                    const data = res.data?.slice(0, (res.data.length - 16));
                    res = JSON.parse(this.encService.deencrypt(tokenFromUI, data));
                    if (res?.ico?.length > 0) {
                        // eslint-disable-next-line no-undef
                        const favIcon: HTMLLinkElement = this.document.querySelector('#partnerFavIcon');
                        if (favIcon) {
                            favIcon.href = 'https://reisen.online/logo/' + res.partnerid + '/' + res.ico;
                        }
                    }
                    this.curentPartner = res;
                    this.showChat = !this.curentPartner.hideChat;
                    this.displaySite = !(this.curentPartner.partnersite?.length > 0);
                    if (isPlatformServer(this.platformId)) {
                        this.state.set(STATE_KEY_PARTNER, <any>res);
                    }
                    this.initPartnerData();
                } else {
                    this.router.navigate(['notfound']);
                }
            });
        }
    }

    async initPartnerData() {
        this.authguard.checkPartnerDatas(this.curentPartner);
        this.initGoogleapi(this.curentPartner);
        this.initMedia();
        if (this.isBrowser) {
            this.secret = this.curentPartner.partnersite;
            this.displaySite = !(this.curentPartner.partnersite?.length > 0);
            if (this.showChat) {
                this.chatService.connectToServer();
            }
            this.checkSendScreen();
        }
    }

    async initGoogleapi(currentPartner) {
        if (currentPartner.googleapikey?.length > 0 && isPlatformServer(this.platformId)) {
            const gaScript = this.document.createElement('script');
            gaScript.setAttribute('defer', '');
            gaScript.setAttribute('src', `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${currentPartner.googleapikey}&libraries=places&callback=Function.prototype`);

            this.document.documentElement.firstChild.appendChild(gaScript);
        }
    }

    async checkSendScreen() {
        // this.chatService.checkScreen()
        //     .pipe(
        //         takeUntil(this.unsubscribe$),
        //     ).subscribe((data: { show: boolean, from: any }) => {
        //         // protected screenService: ScreenService
        //         if (data.show && data?.from?.username?.length > 0) {
        //             this.screenService.sharescreen((canvas) => {
        //                 // console.log(canvas)
        //                 this.chatService.sendScreen(data.from, canvas);
        //             });
        //         }
        //     });
    }

    openChat(): void {
        this.showchatpanel = true;
    }

    closeChat(): void {
        this.showchatpanel = false;
    }

    closeChatAgent(): void {
        this.showChatAgent = false;
    }

    async listenOpenChat() {
        this.chatService.openchat$.pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((open) => {
            if (open) {
                this.openChat();
            }
        });
    }

    async checkShowSite() {
        if (this.secret?.length > 0) {
            this.displaySite = window.localStorage.getItem(this.curentPartner.partnerid + '_pass') === this.secret;
            if (this.displaySite) {
                if (environment.production) {
                    if (!this.detectRobot(navigator.userAgent) && !this.detectChromTest()) {
                        this.cookieService.checkCookis(this.curentPartner.googleanalyticstrackingid, this.curentPartner.googletagmgid, this.curentPartner.googlemapid, this.curentPartner.facebookpixelid, this.curentPartner.partnerid, this.smallMode);
                    }
                }
            }
        } else {
            if (environment.production) {
                if (!this.detectRobot(navigator.userAgent) && !this.detectChromTest()) {
                    this.cookieService.checkCookis(this.curentPartner.googleanalyticstrackingid, this.curentPartner.googletagmgid, this.curentPartner.googlemapid, this.curentPartner.facebookpixelid, this.curentPartner.partnerid, this.smallMode);
                }
            }
        }
        this.initGlobalDatas();
    }

    async initGlobalDatas() {
        this.listenOpenChat();
        this.dateService.initCals();
        fromEvent(window, 'scroll').pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((e) => {
            this.lastposition = window.scrollY;
            this.checkScrollPos(this.lastposition);
        });

        // this.adapter.setLocale('de-DE');
    }

    detectRobot(userAgent: string): boolean {
        const robots = new RegExp(([
            /bot/, /spider/, /crawl/, // GENERAL TERMS
            /APIs-Google/, /AdsBot/, /Googlebot/, // GOOGLE ROBOTS
            /mediapartners/, /Google Favicon/, /GOOGLE/,
            /FeedFetcher/, /Google-Read-Aloud/,
            /DuplexWeb-Google/, /googleweblight/,
            /bing/, /yandex/, /baidu/, /duckduck/, /yahoo/, // OTHER ENGINES
            /ecosia/, /ia_archiver/,
            /facebook/, /instagram/, /pinterest/, /reddit/, // SOCIAL MEDIA
            /slack/, /twitter/, /whatsapp/, /youtube/,
            /semrush/, // OTHER
        ] as RegExp[]).map((r) => r.source).join('|'), 'i'); // BUILD REGEXP + "i" FLAG

        return robots.test(userAgent);
    }

    detectChromTest(): boolean {
        if (navigator.userAgent.match(/(Mozilla\/5\.0 \(Linux; Android 11; moto g power \(2022\)\) AppleWebKit\/537\.36 \(KHTML, like Gecko\) Chrome\/109\.0.0.0 Mobile Safari\/537\.36)|(Mozilla\/5\.0 \(Macintosh; Intel Mac OS X 10_15_7\) AppleWebKit\/537\.36 \(KHTML, like Gecko\) Chrome\/109\.0\.0\.0 Safari\/537\.36)|(Speed Insights)|(Chrome-Lighthouse)/)) {
            return true;
        } else {
            return false;
        }
    }

    checkPass(ev) {
        if (ev.keyCode === 13 && this.pass === this.secret) {
            window.localStorage.setItem(this.curentPartner.partnerid + '_pass', this.secret);
            this.document.body.style.overflow = '';
            this.displaySite = true;
        } else if (ev.keyCode === 13) {
            this.pass = '';
        }
    }

    checkScrollPos(lastposition) {
        // faire quelque chose avec la position du scroll
        if (lastposition >= 500) {
            this.showScrollTopButton = true;
        } else {
            this.showScrollTopButton = false;
        }
    }
}
