import { HttpClientModule, HTTP_INTERCEPTORS, withFetch, provideHttpClient } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CleverInterceptor } from './services/http.service';
import { ChatModule } from './components/chat/chat.module';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { Meta } from '@angular/platform-browser';
import { CookieModule } from './components/cookie/cookie.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { LanguageService } from './services/translate/language.service';
import { WebLanguageService } from './services/translate/web-language.service';
import { I18nService } from './services/i18n.service';
// registerLocaleData(localede);
import { provideClientHydration } from '@angular/platform-browser';
import { PARTNER_TOKEN_BROWSER } from 'browsertockens';
import { BehaviorSubject } from 'rxjs';
import { MatInputModule } from '@angular/material/input';
import { UrlSerializer } from '@angular/router';
import { CustomeUrlSerializer } from './CustomeUrlSerializer';
import { PagerReloadComponent } from './components/pagereload/pagereload.component';

export class DynamicLocaleId extends String {
    constructor(protected service: I18nService) {
        super('');
    }

    override toString() {
        return this.service.langLong;
    }
}
@NgModule({
    declarations: [
        AppComponent,
        PagerReloadComponent
    ],
    imports: [
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        ChatModule,
        MatFormFieldModule,
        CookieModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule
    ],
    providers: [
        // provideHttpClient(withFetch()),
        Meta,
        {
            provide: LOCALE_ID,
            useValue: 'de-DE'
        },
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: '6LfRh1IeAAAAAHtWRke9B5YG-QTnu3HpBhzAckg0'
        },
        { provide: HTTP_INTERCEPTORS, useClass: CleverInterceptor, multi: true },
        { provide: LanguageService, useClass: WebLanguageService },
        {
            provide: LOCALE_ID,
            useClass: DynamicLocaleId,
            deps: [I18nService]
        },
        {
            provide: PARTNER_TOKEN_BROWSER,
            useValue: new BehaviorSubject<string>('')
        },
        {
            provide: UrlSerializer,
            useClass: CustomeUrlSerializer
        },
        provideClientHydration()
    ]
})
export class AppModule { }
