import { DOCUMENT, registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ApplicationRef, HostListener, Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, map, Observable, Subject, takeUntil } from 'rxjs';
import localeDE from '@angular/common/locales/de';
import localeFR from '@angular/common/locales/fr';
import { LanguageService } from './translate/language.service';
import * as moment from 'moment';

@Injectable(
    { providedIn: 'root' }
)
export class I18nService implements OnDestroy {
    private unsubscribe$ = new Subject();
    data: {};
    private _currentLang: string;
    private _locales = {
        de: localeDE,
        fr: localeFR
    };

    private lang$: BehaviorSubject<string>;

    constructor(
        private http: HttpClient,
        private ref: ApplicationRef,
        private languageService: LanguageService,
        @Inject(DOCUMENT) protected dom,
        @Inject(PLATFORM_ID) protected platformId: any,
    ) {
        this.lang$ = new BehaviorSubject('de');
        this.setLanguage(this.languageService.getLanguage('de'));
    }

    @HostListener('window:beforeunload', ['$event'])
    ngOnDestroy(): void {
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
    }

    public get lang(): string {
        return this._currentLang || 'de';
    }

    public get langLong(): string {
        return this._currentLang + '-' + this._currentLang.toLocaleUpperCase() || 'de-DE';
    }

    public get langOb() {
        return this.lang$.asObservable();
    }

    public get langUp() {
        return this._currentLang.toLocaleUpperCase();
    }

    setLanguage(lang) {
        if (this._locales[lang] && this._currentLang !== lang) {
            registerLocaleData(this._locales[lang], lang + '-' + lang.toLocaleUpperCase());
            // console.log(lang + '-' + lang.toLocaleUpperCase())
            this._currentLang = lang;
        }
        this._currentLang = lang;
        moment.locale(this._currentLang);
        this.fetch(lang);
    }

    public translate(key: string, lang?: string): string {
        lang = lang || this.lang;
        return this.data?.[key] || key;
    }

    private fetch(locale: any) {
        const langFilePath = `assets/translation/lang-${locale}.json`;
        this.fetchLangFile(langFilePath).pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((data: any) => {
            this.data = data;
            this.ref.tick();
            this.lang$.next(locale);
        })
    }

    private fetchLangFile(url): Observable<any> {
        const baseurl = this.dom.location.protocol + '//' + this.dom.location.hostname + (this.dom.location.port ? ':' + this.dom.location.port : '') + '/';
        return this.http.get(baseurl + url)
            .pipe(
                map((data: any) => (data?.data || data))
            );
    }
}
