import { NgModule } from '@angular/core';
import { CacheResourcePipe } from './cacheResource.pipe';
import { CleanTextPipe } from './cleanText.pipe';
import { CleverFragmentPipe } from './fragment.pipe';
import { LinkifyPipe } from './linkify.pipe';
import { LinkifyEdomPipe } from './linkifyedom.pipe';
import { SafePipe } from './safe.pipe';
import { SafeHtmlPipe } from './safehtml.pipe';
import { SafeStylePipe } from './safestyle.pipe';
import { Converttohttps } from './converttohttps.pipe';
import { I18nPipe } from './i18n.pipe';
@NgModule({
    declarations: [
        SafePipe,
        SafeHtmlPipe,
        LinkifyPipe,
        CleanTextPipe,
        LinkifyEdomPipe,
        CleverFragmentPipe,
        CacheResourcePipe,
        SafeStylePipe,
        Converttohttps,
        I18nPipe
    ],
    exports: [
        SafePipe,
        SafeHtmlPipe,
        LinkifyPipe,
        CleanTextPipe,
        LinkifyEdomPipe,
        CleverFragmentPipe,
        CacheResourcePipe,
        SafeStylePipe,
        Converttohttps,
        I18nPipe
    ],

    providers: [],
})

export class PipeModule { }
