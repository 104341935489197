import { Inject, Injectable, OnDestroy, Optional, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Partner } from '@app/models/partner/IPartner';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { AuthService } from './auth.service';
import { CookiesService } from './cookies.service';
import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import { PartnerService } from './partner.service';
import { environment } from 'src/environments/environment';
import { PARTNER_TOKEN_BROWSER } from 'browsertockens';
// import { DomSanitizer } from '@angular/platform-browser';
@Injectable({
    providedIn: 'root',
})
export class AuthGuardService implements OnDestroy {
    protected unsubscribe$ = new Subject();
    protected currentPartnerId: string = null;
    protected currentPartner!: Partner;
    protected contentPartnerSubject: BehaviorSubject<string>;
    protected contentPartnerDataSubject: BehaviorSubject<Partner>;
    protected hidenHeaderFooterSubject: BehaviorSubject<boolean>;

    protected trackingid: string;
    protected sessionid: string;

    isBrowser: boolean = false;

    constructor(
        protected partnerService: PartnerService,
        protected router: Router,
        protected auth: AuthService,
        protected cookies: CookiesService,
        // protected sanitizer: DomSanitizer,
        protected location: Location,
        @Inject(PLATFORM_ID) protected platformId: any,
        @Inject(DOCUMENT) protected document: any,
        @Optional() @Inject(PARTNER_TOKEN_BROWSER) private partner_id: BehaviorSubject<string>
    ) {
        this.contentPartnerSubject = new BehaviorSubject(null);
        this.hidenHeaderFooterSubject = new BehaviorSubject(false);
        this.contentPartnerDataSubject = new BehaviorSubject({} as Partner);

        this.isBrowser = isPlatformBrowser(this.platformId);
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
    }

    checkPartnerDatas(res): void {
        if (res?.partnerid?.length > 0) {
            this.currentPartnerId = String(res.partnerid).toLowerCase();
            this.partner_id.next(this.currentPartnerId);
            this.currentPartner = res as Partner;
            this.contentPartnerDataSubject.next(res);
            this.contentPartnerSubject.next(this.currentPartnerId);
            if (this.isBrowser) {
                window.localStorage.setItem('partnerid', this.currentPartnerId);
                this.setCustomColors(res);
                this.initPartnerData();
            }
        } else {
            this.router.navigate(['notfound']);
        }
    }

    getlogo(): string {
        return this.currentPartner?.logo;
    }

    initPartnerData(): void {
        for (let i = 0; i < this.currentPartner.agenturen?.length; i++) {
            if (!this.currentPartner.agenturen[i].aktiv) {
                this.currentPartner.agenturen.splice(i, 1);
                i--;
            }
        }
        if (this.isBrowser) {
            this.auth.isAuthenticated(this.currentPartnerId);
        }
        if (environment.production) {
            this.checktid();
        }
    }

    protected setCustomColors(part: Partner) {
        if (part?.searchpanelbgcolor?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-bg-color', part.searchpanelbgcolor);
        } else {
            this.document.documentElement.style.setProperty('--cl-bg-color', '#8e0622');
        }

        if (part?.searchpanelcolor?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-text-color', part.searchpanelcolor);
        } else {
            this.document.documentElement.style.setProperty('--cl-text-color', '#fff');
        }

        if (part?.successcolor?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-success-color', part.successcolor);
        } else {
            this.document.documentElement.style.setProperty('--cl-success-color', 'rgb(0,130,0)');
        }

        if (part?.successtextcolor?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-success-text-color', part.successtextcolor);
        } else {
            this.document.documentElement.style.setProperty('--cl-success-text-color', '#fff');
        }

        if (part?.errorcolor?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-error-color', part.errorcolor);
        }

        if (part?.errortextcolor?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-error-text-color', part.errortextcolor);
        } else {
            this.document.documentElement.style.setProperty('--cl-error-text-color', 'rgb(180,30,30)');
        }

        if (part?.searchpanelcolor?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-text-color', part.searchpanelcolor);
        }

        if (part?.bgcolor?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-bgcolor', part.bgcolor);
        } else {
            this.document.documentElement.style.setProperty('--cl-bgcolor', '#f3f3f3');
        }

        if (part?.textprimary?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-textprimary', part.textprimary);
        } else {
            this.document.documentElement.style.setProperty('--cl-textprimary', '#545454');
        }

        if (part?.textsecondary?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-textsecondary', part.textsecondary);
        } else {
            this.document.documentElement.style.setProperty('--cl-textsecondary', '#545454');
        }

        if (part?.texta?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-texta', part.texta);
        } else {
            this.document.documentElement.style.setProperty('--cl-texta', '#545454');
        }

        if (part?.textahover?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-textahover', part.textahover);
        }

        if (part?.fontfamily?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-fontfamily', part.fontfamily);
        } else {
            this.document.documentElement.style.setProperty('--cl-fontfamily', '"Helvetica Neue", Helvetica, Arial, sans-serif');
        }

        if (part?.fontsize?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-fontsize', part.fontsize);
        } else {
            this.document.documentElement.style.setProperty('--cl-fontsize', '16px');
        }

        if (part?.margin?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-margin', part.margin);
        } else {
            this.document.documentElement.style.setProperty('--cl-margin', '0');
        }

        if (part?.fontweight?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-fontweight', part.fontweight);
        } else {
            this.document.documentElement.style.setProperty('--cl-fontweight', '300');
        }

        if (part?.letterspacing?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-letterspacing', part.letterspacing);
        } else {
            this.document.documentElement.style.setProperty('--cl-letterspacing', '0.4px');
        }

        if (part?.lineheight?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-lineheight', part.lineheight);
        } else {
            this.document.documentElement.style.setProperty('--cl-lineheight', '1.4');
        }

        if (part?.placeholdercolor?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-placeholdercolor', part.placeholdercolor);
        }

        if (part?.linescolor?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-linescolor', part.linescolor);
        }

        if (part?.lineheight?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-lineheight', part.lineheight);
        } else {
            this.document.documentElement.style.setProperty('--cl-lineheight', '1.4');
        }
        if (part?.extrastyle?.length > 0) {
            const head = this.document.getElementsByTagName('head')[0];
            const style = this.document.createElement('style');
            // const css = this.sanitizer.bypassSecurityTrustStyle(part.extrastyle);
            style.appendChild(this.document.createTextNode(part.extrastyle));
            head.appendChild(style);
        }

        if (this.isBrowser && part?.matomoid?.length > 0) {
            this.initTrackingMatomo(part?.matomoid);
        }
    }

    async initTrackingMatomo(trackingID) {
        const gaScript = this.document.createElement('script');
        gaScript.innerText = `var _paq = window._paq = window._paq || [];_paq.push(['trackPageView']);_paq.push(['enableLinkTracking']);(function(){var u = '//${trackingID}/';_paq.push(['setTrackerUrl', u + 'matomo.php']);_paq.push(['setSiteId', '2']);var d   = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];g.async = true;g.src   = u + 'matomo.js';s.parentNode.insertBefore(g, s);})();`;

        this.document.documentElement.firstChild.appendChild(gaScript);
    }

    getPartnerId(): string {
        return String(this.currentPartnerId).toLowerCase();
    }

    getPartnerLicense(): string {
        return this.currentPartner?.traffics?.licence;
    }

    sethidenHeaderFooterSubject(val): void {
        this.hidenHeaderFooterSubject.next(val);
    }

    get gethidenHeaderFooter$() {
        return this.hidenHeaderFooterSubject.asObservable();
    }

    getPartner(): Partner {
        return this.currentPartner;
    }

    get getPartnerid$() {
        return this.contentPartnerSubject.asObservable();
    }

    get getPartner$() {
        return this.contentPartnerDataSubject.asObservable();
    }

    getPrtBg(): string {
        return this.currentPartner?.searchpanelbgcolor;
    }

    async checktid() {
        this.trackingid = this.cookies.getCookie('CR_' + this.currentPartnerId + '_TID');
        this.partnerService.request({ trackingid: this.trackingid, partnerid: this.currentPartnerId }, 'checktid').pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((res) => {
            if (res.trackingid?.length === 24) {
                this.trackingid = res.trackingid;
                this.sessionid = res.sessionid;
                this.cookies.setCookie('CR_' + this.currentPartnerId + '_TID', this.trackingid, 365);
                window.sessionStorage.setItem('CR_' + this.currentPartnerId + '_SID', this.sessionid);
                this.setSessionTrackingurls(this.location.path());
                this.location.onUrlChange(url => this.setSessionTrackingurls(url));
            }
        });
    }

    getGiataLicense(): string {
        return this.currentPartner?.giatalicence;
    }

    async setSessionTrackingurls(url) {
        const target = {
            partnerid: this.currentPartnerId,
            trackingid: this.trackingid,
            sessionid: this.sessionid,
            url: url
        };

        this.partnerService.request(target, 'setSessionTrackingurls').pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe(() => { });
    }
}
