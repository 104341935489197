import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { isPlatformServer } from '@angular/common';
import { environment } from '../../environments/environment';
import { EncryptionService } from './encyption.service';

@Injectable(
    { providedIn: 'root' }
)
export class CleverInterceptor implements HttpInterceptor {
    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private encryption: EncryptionService,
    ) { }

    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (httpRequest.method === 'POST') {
            let url = 'http://127.0.0.1:3322/';
            if (isPlatformServer(this.platformId) || !environment.production) {
                url = url + httpRequest.url;
            } else {
                url = httpRequest.url;
            }
            const msg = this.encryption.encrypt(httpRequest.body.body);
            return next.handle(httpRequest.clone({ url: url, body: { body: msg } }));
        } else {
            return next.handle(httpRequest);
        }
    }
}
