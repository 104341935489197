import { Component, OnInit, HostListener, OnDestroy, ElementRef, Renderer2, PLATFORM_ID, Inject, ChangeDetectorRef, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { ChatService } from '../../services/chat.service';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { ChatUser } from '../../models/chat/ChatUser';
import { Message } from '../../models/chat/Message';
import { environment } from '../../../environments/environment';
import { EncryptionService } from '../../services/encyption.service';
import { NavigationExtras, Router } from '@angular/router';
import { AuthGuardService } from '../../services/auth-guard.service';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { TerminkalenderService } from '../../services/terminkalender.service';
import { Mitarbeiter } from '../../models/mitarbeiter/Mitarbeiter';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { Office } from '../../models/mitarbeiter/Office';
import { Partner } from '../../models/partner/IPartner';
import { takeUntil } from 'rxjs/operators';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject();
    @Output() triggerclosechat: EventEmitter<boolean> = new EventEmitter();
    @ViewChild('imgmdl', { static: true }) imgmdl: TemplateRef<any>;
    regg = /<(div|p|br)[^<]*?>/g;
    regg1 = /<\/(div|p)*?>/g;
    regg2 = /<br>/g;
    messages: Message[];
    chatpanel: boolean = true;
    typing: boolean = false;
    desktopTemplate: string = 'xl';
    IchatUser: ChatUser;
    currentFrom: ChatUser;
    timeout;
    focus: boolean = false;
    imgPermission: boolean = false;
    cleartipingtimeout;
    scrollHeight: number;
    partnerid: string = '';
    mitarbeiters: Mitarbeiter[];
    mitarbObservable: Observable<Mitarbeiter[]>;
    currentMitarbeiter: Mitarbeiter;
    currentOffice: Office;
    offices: Office[];
    partner: Partner;
    reloadconfirmation: boolean = false;
    isBrowser: boolean = false;
    showwaiting30secLoading: boolean = false;
    timer30: number = 30;
    showwaiting30secLoadingSub: Subscription;
    showphonformular: boolean = false;
    showphonsktvformular: boolean = false;
    showbookingformular: boolean = false;
    showmailformular: boolean = false;
    showTvInputChat: boolean = false;
    firstWaiting: boolean = true;
    secondWaiting: boolean = false;
    lastWaiting: boolean = false;
    activechatbtn: boolean = false;
    sktvnumber: string = '';
    bookingnumber: string = '';

    showbigImage: boolean = false;
    loadingchatmodels: boolean = false;
    sendImgBtn: boolean = false;
    selectedblb: any;

    trackForm: FormGroup;

    gobackinmenu(): void { };
    showmb1: boolean = false;
    showmb2: boolean = false;
    showmb3: boolean = false;
    showmb3whaite: boolean = false;
    showmb4: boolean = false;
    showmb4whaite: boolean = false;
    showmb5: boolean = false;
    showmb5whaite: boolean = false;
    showmb6: boolean = false;
    showmb7: boolean = false;
    showandereFrageformular: boolean = false;
    showandereFrageformular1: boolean = false;
    showandereFrageformular2: boolean = false;
    sktvnumbererror: boolean = false;
    bookingnumberrerror: boolean = false;

    @ViewChild('filesBrowser') filesBrowser: any;

    currentimage: string;

    rkp1: string = '?longitude=0.1&latitude=42.59151&startzoom=1&showtravelwarning=false&showcorona=false&showcompass=false&showinternational=false&showcoronatravel=true&poigroupid=&datamapid=&country=%7B"name":"Spanien","code":"ES"%7D';
    rkp2: string = '?longitude=0.1&latitude=42.59151&startzoom=1&showtravelwarning=false&showcorona=true&showcompass=false&showinternational=false&showcoronatravel=false&poigroupid=&datamapid=&country=%7B"name":"Spanien","code":"ES"%7D';
    msg1: string = 'Hallo, hier ist ';
    msg11: string = '. Ich sehe, dass Du gerade nach Reiseangeboten oder Reiseinformationen suchst. Wie kann ich Dir behilflich sein?';
    msg12: string = '. Du hast eine Frage zu einer bestehenden Reisebuchung. Ich bin gleich für Dich da. Du kannst mir schon mal Deine Buchungsnummer nennen.';
    msg3: string = 'Hallo, ich bin\'s. Bin leider noch verhindert. Du kannst gerne auf mich warten oder aus nachfolgenden Möglichkeiten wählen.';

    msg4: string = 'Hallo, hier ist der digitale Reise-Assistent von '
    msg41: string = '. Sie haben eine Frage zu einer bestehenden Reisebuchung. Ggfs. kann ich Ihnen schon weiterhelfen?';
    msg42: string = '. Du interessierst Dich für eine neue Reise. Gebe mir doch bitte schon mal einige Informationen - ggfs. kann ich Dir schon weiterhelfen?';

    msg5: string = 'Hallo, ';
    msg51: string = ' ist leider immer noch verhindert. Ich leite Deine Frage gerne an sie weiter – sie wird sich schnellstmöglich bei Dir melden. Vielen Dank für Dein Verständnis.';

    msg6: string = 'Hallo, hier ist ';
    msg61: string = '. Du hast eine Frage zu einer sonnenklar.TV Bestellnummer. Ich bin gleich für Dich da. Du kannst mir schon mal die Bestellnummer nennen.';
    msg7: string = 'Hallo, hier ist der digitale Reise-Assistent von ';
    msg71: string = '. Sie haben eine Frage zu einer sonnenklar.TV Bestellnummer. Ggfs. kann ich Ihnen schon weiterhelfen?';

    msg8: string = 'Hallo, hier ist ';
    msg81: string = '. Du interessierst Dich für eine neue Reise. Ich bin gleich für Dich da.';
    msfr1: string = 'Vielen Dank, wir werden Sie so schnell wie möglich telefonisch kontaktieren !';
    msfr2: string = 'Vielen Dank, wir werden Sie so schnell wie möglich kontaktieren !';

    constructor(
        protected chatService: ChatService,
        protected router: Router,
        protected guardService: AuthGuardService,
        protected mediaObserver: MediaObserver,
        protected dialog: MatDialog,
        protected encservice: EncryptionService,
        protected terminkalenderService: TerminkalenderService,
        protected cdr: ChangeDetectorRef,
        protected recaptchaV3Service: ReCaptchaV3Service,
        protected render: Renderer2,
        private fb: FormBuilder,
        @Inject(DOCUMENT) protected document: any,
        @Inject(PLATFORM_ID) protected platformId: any
    ) {
        this.isBrowser = isPlatformBrowser(this.platformId);
    }

    ngOnInit(): void {
        if (this.isBrowser) {
            this.trackForm = this.fb.group({
                text: new FormControl('', []),
            });
            if (!this.detectRobot(navigator.userAgent) && !this.detectChromTest()) {
                this.initMedia();
                this.initPartner();
                this.messages = [];
            }
        }
    }

    resetRobot(): void {
        this.stopTimer();
        this.closeAllbtns();
        this.cdr.detectChanges();
    }

    detectRobot(userAgent: string): boolean {
        const robots = new RegExp(([
            /bot/, /spider/, /crawl/, // GENERAL TERMS
            /APIs-Google/, /AdsBot/, /Googlebot/, // GOOGLE ROBOTS
            /mediapartners/, /Google Favicon/, /GOOGLE/,
            /FeedFetcher/, /Google-Read-Aloud/,
            /DuplexWeb-Google/, /googleweblight/,
            /bing/, /yandex/, /baidu/, /duckduck/, /yahoo/, // OTHER ENGINES
            /ecosia/, /ia_archiver/, /cortex/,
            /facebook/, /instagram/, /pinterest/, /reddit/, // SOCIAL MEDIA
            /slack/, /twitter/, /whatsapp/, /Whatsapp/, /youtube/,
            /semrush/, // OTHER
        ] as RegExp[]).map((r) => r.source).join('|'), 'i'); // BUILD REGEXP + "i" FLAG

        return robots.test(userAgent);
    }

    detectChromTest(): boolean {
        if (navigator.userAgent.match(/(Mozilla\/5\.0 \(Linux; Android 11; moto g power \(2022\)\) AppleWebKit\/537\.36 \(KHTML, like Gecko\) Chrome\/109\.0.0.0 Mobile Safari\/537\.36)|(Mozilla\/5\.0 \(Macintosh; Intel Mac OS X 10_15_7\) AppleWebKit\/537\.36 \(KHTML, like Gecko\) Chrome\/109\.0\.0\.0 Safari\/537\.36)|(Speed Insights)|(Chrome-Lighthouse)/)) {
            return true;
        } else {
            return false;
        }
    }

    async initPartner() {
        this.guardService.getPartner$.pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((partner: Partner) => {
            if (partner?.partnerid?.length > 0) {
                this.partner = partner;
                this.partnerid = partner?.partnerid;
                this.initChatDaten();
            }
        });
    }

    async initMedia() {
        this.mediaObserver.asObservable().pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((change: MediaChange[]) => {
            this.desktopTemplate = change[0].mqAlias;
        });
    }

    async initChatDaten() {
        if (this.partnerid?.length > 0) {
            this.terminkalenderService.request({ partnerid: this.partnerid }, 'getchatmitarbeiters').pipe(
                takeUntil(this.unsubscribe$)
            ).subscribe(res => {
                if (!res?.error) {
                    this.mitarbeiters = res;
                    const tmp = [];
                    for (let k = 0; k < res?.length; k++) {
                        if (res[k].userid > 0) {
                            tmp.push(res[k]);
                        }
                    }
                    if (this.mitarbeiters?.length > 0) {
                        const r = Math.floor(Math.random() * tmp?.length);
                        this.currentMitarbeiter = tmp[r];
                        this.activechatbtn = true;
                        this.initChat();
                    }
                }
            });
        }
    }

    async initFromUser(mit) {
        for (let i = 0; i < this.mitarbeiters?.length; i++) {
            if (this.mitarbeiters[i].email === mit) {
                this.currentMitarbeiter = this.mitarbeiters[i];
                this.currentFrom = {
                    avatar: this.currentMitarbeiter.avatar,
                    username: this.currentMitarbeiter.email,
                    firstname: this.currentMitarbeiter.firstname,
                    lastname: this.currentMitarbeiter.lastname,
                    tel: this.currentMitarbeiter.tel,
                } as ChatUser;
                i = this.mitarbeiters?.length;
            }
        }
    }

    getAvatar(mit): boolean {
        if (mit?.username?.length > 0) {
            for (let i = 0; i < this.mitarbeiters?.length; i++) {
                if (this.mitarbeiters[i].email === mit.username) {
                    if (this.mitarbeiters[i].avatar?.length > 0) {
                        mit.avatar = this.mitarbeiters[i].avatar;
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        }

        return false;
    }

    async openBigImage(img) {
        this.currentimage = img;
        const target = {
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            autoFocus: false,
            panelClass: 'customModalimg'
        }
        const resf = this.dialog.open(this.imgmdl, target);
        resf.afterClosed().pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe(() => {
            this.currentimage = null;
        });
    }

    async downloadImg() {
        const link = document.createElement('a');
        link.download = 'suntourImg.png';
        link.href = this.currentimage;
        link.click();
    }

    @HostListener('window:beforeunload', ['$event'])
    ngOnDestroy(): void {
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
    }

    async newChat() {
        this.reloadconfirmation = true; // show confirmation
    }

    async resetChat() {
        this.resetRobot();
        this.messages = [];
        const tmp_name = window.localStorage.getItem(this.partnerid + '_sessionid');
        window.localStorage.setItem(this.partnerid + '_sessionid', '');
        this.chatService.resetUsername(tmp_name);
    }

    async initsocket() {
        this.chatService.connectToServer();

        this.initUserNameOb();
        this.checkNewMessageOb();
        this.checkTypingOb();
        this.checkImgPermission();
        this.checkStopRobot();
    }

    async checkStopRobot() {
        this.chatService.onsendStopRobot()
            .pipe(
                takeUntil(this.unsubscribe$),
                distinctUntilChanged()
            ).subscribe((data: any) => {
                this.IchatUser.stopRobot = data.stopRobot;
                if (data.stopRobot) {
                    this.resetRobot();
                }
            });
    }

    async checkTypingOb() {
        this.chatService.onTyping()
            .pipe(
                takeUntil(this.unsubscribe$),
                distinctUntilChanged()
            ).subscribe((typing: boolean) => {
                this.typing = typing;
                clearTimeout(this.cleartipingtimeout);
                this.cleartipingtimeout = setTimeout(() => {
                    this.typing = false;
                }, 30000);
            });
    }

    async checkImgPermission() {
        this.chatService.imgPermission()
            .pipe(
                takeUntil(this.unsubscribe$),
                distinctUntilChanged()
            ).subscribe((prm: boolean) => {
                this.imgPermission = prm;
            });
    }

    async checkNewMessageOb() {
        this.chatService.onNewMessage()
            .pipe(
                takeUntil(this.unsubscribe$),
                distinctUntilChanged(),
                filter((msg: Message) => (msg.message?.trim().length > 0 && msg.message?.trim().length < 10000 && msg.type !== 'zoom' && msg.type !== 'link') || (msg.type === 'zoom' || msg.type === 'link'))
            ).subscribe(msg => {
                this.resetRobot();
                const mm = msg?.message?.replace(/ /g, '+');
                const tokenFromUI = mm?.slice(-16);
                const data = mm?.slice(0, (mm?.length - 16));
                const msgd = this.encservice.deencrypt(tokenFromUI, data);
                const realmsg = JSON.parse(msgd);
                msg.message = realmsg;
                this.messages.push(msg);
                setTimeout(() => {
                    this.scroolDown();
                }, 200);
            });
    }

    async initUserNameOb() {
        this.chatService.updateusername()
            .pipe(
                takeUntil(this.unsubscribe$),
            ).subscribe((user: any) => {
                if (user) {
                    this.IchatUser = {
                        last_connection: new Date(),
                        status: 1,
                        type: 'web',
                        username: user.username,
                        stopRobot: user.stopRobot,
                    } as ChatUser;
                    if (user.username?.length > 0) {
                        this.getmymesages(user.username);
                    }
                }
            });
    }

    async getmymesages(username) {
        this.chatService.getmymesages<string, Array<Message>>(username, (messages) => {
            for (let i = 0; i < messages.length; i++) {
                const mm = messages[i].message.replace(/ /g, '+');
                const tokenFromUI = mm?.slice(-16);
                const data = mm?.slice(0, (mm.length - 16));
                try {
                    const realmsg = JSON.parse(this.encservice.deencrypt(tokenFromUI, data));
                    messages[i].message = realmsg;
                } catch (error) {
                    // console.log(error);
                }
            }
            this.messages = messages;
            if (this.IchatUser.stopRobot) {
                this.resetRobot();
                setTimeout(() => {
                    this.scroolDown();
                }, 300);
            } else {
                this.startTalk();
            }
        });
    }

    async sendButtonClick() {
        const tmp = this.trackForm.value.text?.replace(this.regg, '')?.replace(this.regg1, '\n');
        if (tmp.length > 0) {
            this.sendMessage(this.trackForm.value.text?.replace(this.regg, '')?.replace(this.regg1, '\n'));
            this.trackForm.controls['text'].setValue('');
        }
    }

    async sendMessage(text, isOption?: boolean, type?: string) {
        // clearTimeout(this.cleartimeoutwaitingChat);
        if (!this.IchatUser) {
            this.IchatUser = {
                last_connection: new Date(),
                status: 1,
                type: 'web',
                username: window.localStorage.getItem(this.partnerid + '_sessionid')
            } as ChatUser;
        }
        const message = {
            message: this.encservice.encrypt(text),
            type: type || 'text',
            from: this.IchatUser,
            to: {
                last_connection: new Date(),
                status: 1,
                type: 'backoffice',
                username: 'backoffice'
            },
            status: 1,
            date: new Date()
        } as Message;
        // send message
        this.chatService.sendMessage<Message>(message, (res) => {
            // Add message in to the list
            message.message = text;
            this.messages.push(message);
            setTimeout(() => {
                this.scroolDown();
            }, 100);
            if (res === 'ok') {
                message.status = 1;
            } else {
                message.status = -1;
            }
            if (!this.IchatUser.stopRobot && !isOption) {
                this.resetRobot();
                this.gobackinmenu = this.returnmb1.bind(this);
                this.waiting30sec(() => {
                    this.resetRobot();
                    this.createHibridMessage(this.msg3, () => {
                        this.showmb2 = true;
                        setTimeout(() => {
                            this.scroolDown();
                        }, 300);
                    });
                });
            }
            // this.createRobotMessage('einen kurzen Moment bitte. Ich bin gleich für sie da !');
        });
    }

    keypress(ev) {
        if (ev.which === 13 && !ev.shiftKey) {
            ev.preventDefault();
            ev.stopPropagation();
            const tmp = this.trackForm.value.text?.replace(this.regg, '')?.replace(this.regg1, '\n');
            if (tmp.length > 0) {
                this.sendMessage(tmp);
                this.trackForm.controls['text'].setValue('');
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    this.chatService.sendTyping(this.IchatUser, false);
                }, 3000);
            }
        } else {
            this.chatService.sendTyping(this.IchatUser, true);
            clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                this.chatService.sendTyping(this.IchatUser, false);
            }, 30000);
        }
    }

    async gotozoom(params) {
        if (environment.production) {
            const domain = this.document.domain.startsWith('demo.') ? 'https://zoom' : 'https://zoom.';
            window.open(domain + this.document.domain + '?param=' + params, '_blank', 'noopener');
        } else {
            window.open('https://localhost:3336?param=' + params, '_blank', 'noopener');
        }
    }

    async gotoLink(link) {
        window.open(link, '_blank', 'noopener');
    }

    async scroolDown() {
        const container = this.document.getElementById('msgContainer');
        if (container) {
            container.scrollTo({ top: container?.scrollHeight, behavior: 'smooth' });
        }
    }

    async initChat() {
        this.scroolDown();
        this.initsocket();
        this.initFromUser(this.currentMitarbeiter?.email);
    }

    async createRobotMessage(text) {
        const cryptos = this.encservice.encrypt(text);
        const message = {
            message: cryptos,
            type: 'robot',
            from: {
                last_connection: new Date(),
                status: 1,
                type: 'backoffice',
                username: 'backoffice',
                firstname: 'Clever',
                lastname: 'Reisen'
            },
            to: {
                last_connection: new Date(),
                status: 1,
                type: 'web',
                username: window.localStorage.getItem(this.partnerid + '_sessionid')
            },
            status: 1,
            date: new Date()
        } as Message;
        this.chatService.sendMessage<Message>(message, (res) => {
            // Add message in to the list
            message.message = text;
            this.messages.push(message);
            setTimeout(() => {
                this.scroolDown();
            }, 200);
        });
    }

    async closeChat() {
        this.chatpanel = false;
        this.resetRobot();
        this.triggerclosechat.emit(true);
    }

    async gotoCalendar() {
        this.sendMessage('Ich vereinbare einen persönlichen Termin.', true);
        this.router.navigate(['terminkalender']);
        if (this.desktopTemplate === 'xs' || this.desktopTemplate === 'sm') {
            this.closeChat();
        }
    }

    async gotSktv() {
        if (this.sktvnumber?.length > 0) {
            this.sktvnumbererror = false;
            const navigationExtras: NavigationExtras = {
                queryParams: { params: this.encservice.encrypt({ extra: { orderId: this.sktvnumber } }) }
            };
            const url = this.router.createUrlTree(['sktv'], navigationExtras).toString();
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.setAttribute('visibility', 'hidden');
            link.click();
        } else {
            this.sktvnumbererror = true;
            setTimeout(() => {
                this.scroolDown();
            }, 200);
        }
    }

    async createHibridMessage(text, cb) {
        const cryptos = this.encservice.encrypt(text);
        const message = {
            message: cryptos,
            type: 'robot',
            from: {
                last_connection: new Date(),
                status: 1,
                type: 'backoffice',
                username: this.currentFrom?.username,
                firstname: this.currentFrom?.firstname,
                lastname: this.currentFrom?.lastname
            },
            to: {
                last_connection: new Date(),
                status: 1,
                type: 'web',
                username: window.localStorage.getItem(this.partnerid + '_sessionid')
            },
            status: 1,
            date: new Date()
        } as Message;
        this.chatService.sendMessage<Message>(message, (res) => {
            // Add message in to the list
            message.message = text;
            message.avatar = this.currentFrom?.avatar;
            this.messages.push(message);
            cb();
        });
    }

    sendemail(val, token) {
        let html = '<b>Anfrage von chat</b>';
        html += '<br><br>';
        html += 'Vorname: ' + val.firstname;
        html += '<br><br>';
        html += 'Name: ' + val.lastname;
        html += '<br><br>';
        html += 'Telefonnummer: ' + val.tel;
        html += '<br><br>';
        html += 'Email: ' + val.email;
        html += '<br><br>';
        html += 'Message: ' + val.message;
        html += '<br><br>';
        let emailBody = '';
        emailBody += '<html>';
        emailBody += '<head>';
        emailBody += '<meta http-equiv="Content-Type" content="text/html; charset=UTF-8">';
        emailBody += '<meta name="viewport" content="width=device-width, initial-scale=1.0">';
        emailBody += '<style type="text/css">';
        emailBody += `
        HTMLBody, body {
            background-color: #f3f3f3;
        }
        table {
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
            border-collapse: collapse;
            border-spacing: 0px;
            outline: 0;
            margin: 5px;
        }
        `;
        emailBody += '</style>';
        emailBody += '</head>';
        emailBody += '<HTMLBody bgcolor="#f3f3f3">';
        emailBody += '<table><tr><td>';
        emailBody += html;
        emailBody += '</td></tr></table>';
        emailBody += '</HTMLBody></html>';
        this.chatService.sendemail({
            partnerid: this.partnerid,
            email: this.partner.email,
            subject: 'Anfrage von chat',
            datahtml: emailBody,
            token: token
        }, (res) => {
            // console.log(res);
        });
    }

    // new version
    async startTimer(cb) {
        this.showwaiting30secLoading = true;
        this.timer30 = 30;
        const source = timer(0, 1000);
        this.showwaiting30secLoadingSub?.unsubscribe();
        this.showwaiting30secLoadingSub = source.subscribe(_ => {
            if (this.timer30 > 0) {
                this.timer30 = this.timer30 - 1;
                this.cdr.markForCheck();
            } else {
                cb();
            }
        });
    }

    async startTalk() {
        if (!this.messages[this.messages?.length - 1]?.message.endsWith(this.msg11)) {
            const text = this.msg1 + this.currentFrom?.firstname + this.msg11;
            this.createHibridMessage(text, () => {
                this.showmb1 = true;
                setTimeout(() => {
                    this.scroolDown();
                }, 300);
            });
        } else {
            if (!this.IchatUser.stopRobot) {
                this.showmb1 = true;
            }
            setTimeout(() => {
                this.scroolDown();
            }, 300);
        }
    }

    waiting30sec(cb): void {
        this.stopTimer();
        setTimeout(() => {
            this.startTimer(cb);
            setTimeout(() => {
                this.scroolDown();
            }, 200);
        }, 200);
    }

    closeAllbtns(): void {
        this.showphonformular = false;
        this.showmailformular = false;
        this.reloadconfirmation = false;
        this.showandereFrageformular = false;
        this.showandereFrageformular1 = false;
        this.showandereFrageformular2 = false;
        this.showmb1 = false;
        this.showmb2 = false;
        this.showmb3 = false;
        this.showmb4 = false;
        this.showmb5 = false;
        this.showmb6 = false;
        this.showmb7 = false;
    }

    stopTimer(): void {
        this.showwaiting30secLoading = false;
        this.showwaiting30secLoadingSub?.unsubscribe();
    }

    async startWeitereWachten(msg) {
        this.closeAllbtns();
        this.showmb3whaite = false;
        setTimeout(() => {
            this.sendMessage(msg, true);
            this.waiting30sec(() => {
                this.stopTimer(); // stop timer
                this.showmb3whaite = true;
            });
            setTimeout(() => {
                this.scroolDown();
                this.showmb3 = true;
                setTimeout(() => {
                    this.scroolDown();
                }, 200);
            }, 500);
            // setTimeout(() => {
            //     const text = this.msg4 + this.currentFrom.firstname + this.msg41;
            //     this.createRobotMessage(text);
            //     setTimeout(() => {
            //         this.scroolDown();
            //         this.showmb3 = true;
            //         setTimeout(() => {
            //             this.scroolDown();
            //         }, 200);
            //     }, 500);
            // }, 500);
        }, 200);
    }

    async startWeitereWachtenV4(msg) {
        this.closeAllbtns();
        this.showmb4whaite = false;
        setTimeout(() => {
            this.sendMessage(msg, true);
            this.waiting30sec(() => {
                this.stopTimer(); // stop timer
                this.showmb4whaite = true;
            });
            setTimeout(() => {
                this.scroolDown();
                this.showmb5 = true;
                setTimeout(() => {
                    this.scroolDown();
                }, 200);
            }, 500);
            // setTimeout(() => {
            //     const text = this.msg4 + this.currentFrom.firstname + this.msg42;
            //     this.createRobotMessage(text);
            //     setTimeout(() => {
            //         this.scroolDown();
            //         this.showmb5 = true;
            //         setTimeout(() => {
            //             this.scroolDown();
            //         }, 200);
            //     }, 500);
            // }, 500);
        }, 200);
    }

    async startWeitereWachtenV5(msg) {
        this.closeAllbtns();
        this.showmb5whaite = false;
        setTimeout(() => {
            this.sendMessage(msg, true);
            this.waiting30sec(() => {
                this.stopTimer(); // stop timer
                this.showmb5whaite = true;
            });
            setTimeout(() => {
                this.scroolDown();
                this.showmb7 = true;
                setTimeout(() => {
                    this.scroolDown();
                }, 200);
            }, 500);
            // setTimeout(() => {
            //     const text = this.msg4 + this.currentFrom.firstname + this.msg42;
            //     this.createRobotMessage(text);
            //     setTimeout(() => {
            //         this.scroolDown();
            //         this.showmb7 = true;
            //         setTimeout(() => {
            //             this.scroolDown();
            //         }, 200);
            //     }, 500);
            // }, 500);
        }, 200);
    }

    async gotobackbooking() {
        this.showbookingformular = false;
        this.returnmb1();
    }

    async sendbookingnumber() {
        if (this.bookingnumber?.length > 0) {
            this.bookingnumberrerror = false;
            this.showbookingformular = false;
            this.sendMessage(this.bookingnumber, true);
            setTimeout(() => {
                this.gobackinmenu = this.returnmb1.bind(this);
                this.waiting30sec(() => {
                    this.stopTimer(); // stop timer
                    this.createHibridMessage(this.msg3, () => {
                        this.showmb2 = true;
                        setTimeout(() => {
                            this.scroolDown();
                        }, 300);
                    });
                });
                setTimeout(() => {
                    this.scroolDown();
                }, 200);
            }, 200);
        } else {
            this.bookingnumberrerror = true;
            setTimeout(() => {
                this.scroolDown();
            }, 200);
        }
    }

    async startReisebuchung(msg) {
        this.closeAllbtns();
        setTimeout(() => {
            this.sendMessage(msg, true);
            setTimeout(() => {
                const text = this.msg1 + this.currentFrom.firstname + this.msg12;
                this.createHibridMessage(text, () => {
                    this.showbookingformular = true;
                    setTimeout(() => {
                        this.scroolDown();
                    }, 200);
                });
            }, 500);
            // setTimeout(() => {
            //     const text = this.msg1 + this.currentFrom.firstname + this.msg12;
            //     this.createHibridMessage(text, () => {
            //         this.gobackinmenu = this.returnmb1.bind(this);
            //         this.waiting30sec(() => {
            //             this.stopTimer(); // stop timer
            //             this.createHibridMessage(this.msg3, () => {
            //                 this.showmb2 = true;
            //                 setTimeout(() => {
            //                     this.scroolDown();
            //                 }, 300);
            //             });
            //         });
            //         setTimeout(() => {
            //             this.scroolDown();
            //         }, 200);
            //     });
            // }, 500);
        }, 200);
    }

    async startsktv(msg) {
        this.closeAllbtns();
        setTimeout(() => {
            this.sendMessage(msg, true);
            setTimeout(() => {
                const text = this.msg6 + this.currentFrom?.firstname + this.msg61;
                this.createHibridMessage(text, () => {
                    this.showphonsktvformular = true;
                    setTimeout(() => {
                        this.scroolDown();
                    }, 200);
                });
            }, 500);
            // setTimeout(() => {
            //     const text = this.msg6 + this.currentFrom.firstname + this.msg61;
            //     this.createHibridMessage(text, () => {
            //         this.gobackinmenu = this.returnmb1.bind(this);
            //         this.waiting30sec(() => {
            //             this.stopTimer(); // stop timer
            //             this.createHibridMessage(this.msg3, () => {
            //                 this.showmb6 = true;
            //                 setTimeout(() => {
            //                     this.scroolDown();
            //                 }, 300);
            //             });
            //         });
            //         setTimeout(() => {
            //             this.scroolDown();
            //         }, 200);
            //     });
            // }, 500);
        }, 200);
    }

    async gotobacksktv() {
        this.showphonsktvformular = false;
        this.returnmb1();
    }

    async sendsktvnumber() {
        if (this.sktvnumber?.length > 0) {
            this.sktvnumbererror = false;
            this.showphonsktvformular = false;
            this.sendMessage(this.sktvnumber, true);
            setTimeout(() => {
                this.gobackinmenu = this.returnmb1.bind(this);
                this.waiting30sec(() => {
                    this.stopTimer(); // stop timer
                    this.createHibridMessage(this.msg3, () => {
                        this.showmb6 = true;
                        setTimeout(() => {
                            this.scroolDown();
                        }, 300);
                    });
                });
                setTimeout(() => {
                    this.scroolDown();
                }, 200);
            }, 200);
        } else {
            this.sktvnumbererror = true;
            setTimeout(() => {
                this.scroolDown();
            }, 200);
        }
    }

    async startNeueReise(msg) {
        this.closeAllbtns();
        setTimeout(() => {
            this.sendMessage(msg, true);
            setTimeout(() => {
                const text = this.msg8 + this.currentFrom?.firstname + this.msg81;
                this.createHibridMessage(text, () => {
                    this.gobackinmenu = this.returnmb1.bind(this);
                    this.waiting30sec(() => {
                        this.stopTimer(); // stop timer
                        this.createHibridMessage(this.msg3, () => {
                            this.showmb4 = true;
                            setTimeout(() => {
                                this.scroolDown();
                            }, 300);
                        });
                    });
                    setTimeout(() => {
                        this.scroolDown();
                    }, 200);
                });
            }, 500);
        }, 200);
    }

    returnmb1(): void {
        this.stopTimer(); // stop timer
        this.closeAllbtns();
        this.showmb1 = true; // show origin buttons
        setTimeout(() => {
            this.scroolDown();
        }, 200);
    }

    returnmb2(): void {
        this.stopTimer(); // stop timer
        this.closeAllbtns();
        this.showmb2 = true; // show origin buttons
        setTimeout(() => {
            this.scroolDown();
        }, 200);
    }

    returnmb3(): void {
        this.stopTimer(); // stop timer
        this.closeAllbtns();
        this.showmb1 = true; // show origin buttons
        setTimeout(() => {
            this.scroolDown();
        }, 200);
    }

    returnmb4(): void {
        this.stopTimer(); // stop timer
        this.closeAllbtns();
        this.showmb6 = true; // show origin buttons
        setTimeout(() => {
            this.scroolDown();
        }, 200);
    }

    async gotoReisekompass(msg, params) {
        this.sendMessage(msg, true);
        const url = this.router.createUrlTree(['contents']).toString();
        this.router.navigateByUrl(url + params);
        if (this.desktopTemplate === 'xs' || this.desktopTemplate === 'sm') {
            this.closeChat();
        }
    }

    async gotoAnfrage(msg) {
        this.sendMessage(msg, true);
        const url = this.router.createUrlTree(['anfrage']).toString();
        this.router.navigateByUrl(url);
        if (this.desktopTemplate === 'xs' || this.desktopTemplate === 'sm') {
            this.closeChat();
        }
    }

    focusInput(msg) {
        this.focus = true;
        this.cdr.detectChanges();
        setTimeout(() => {
            this.closeAllbtns();
            this.sendMessage(msg, true);
        }, 500);
    }

    showPhonNumber() {
        this.closeAllbtns();
        this.sendMessage('Ich bitte um einen Rückruf.', true);
        this.showphonformular = true;
        setTimeout(() => {
            this.scroolDown();
        }, 200);
    }

    openandereFrageformular() {
        this.closeAllbtns();
        this.sendMessage('Ich habe eine Frage zu einem anderen Thema.', true);
        this.showandereFrageformular = true;
        setTimeout(() => {
            this.scroolDown();
        }, 200);
    }

    openandereFrageformular1() {
        this.closeAllbtns();
        this.sendMessage('Ich habe eine andere Frage.', true);
        this.showandereFrageformular1 = true;
        setTimeout(() => {
            this.scroolDown();
        }, 200);
    }

    openandereFrageformular2() {
        this.closeAllbtns();
        this.sendMessage('Ich habe eine Frage zu einem anderen Thema.', true);
        this.showandereFrageformular2 = true;
        setTimeout(() => {
            this.scroolDown();
        }, 200);
    }

    showMail() {
        this.closeAllbtns();
        this.sendMessage('Ich schicke eine Email.', true);
        this.showmailformular = true;
        setTimeout(() => {
            this.scroolDown();
        }, 200);
    }

    async sendFormular(val, msfr) {
        this.closeAllbtns();
        this.recaptchaV3Service.execute('chatphonenumberformular').pipe(
            takeUntil(this.unsubscribe$),
        ).subscribe((token) => {
            this.createRobotMessage(msfr);
            setTimeout(() => {
                this.scroolDown();
            }, 150);
            this.sendemail(val, token);
        });
    }

    async closeFormular(val) {
        if (val) {
            this.closeAllbtns();
            this.showmb2 = true;
        }
    }

    async closeAndereFrageFormular(val) {
        if (val) {
            this.closeAllbtns();
            this.showmb3 = true;
        }
    }

    async closeAndereFrageFormular1(val) {
        if (val) {
            this.closeAllbtns();
            this.showmb5 = true;
        }
    }

    async closeAndereFrageFormular2(val) {
        if (val) {
            this.closeAllbtns();
            this.showmb7 = true;
        }
    }

    async openFilesBrowser(ev) {
        ev.stopPropagation();
        if (this.filesBrowser && this.filesBrowser.nativeElement) {
            this.filesBrowser.nativeElement.click();
            ev.stopPropagation();
        }
    }

    async multipleFilesChangeEvent(fileInput: any) {
        if (fileInput.target && fileInput.target.files && fileInput.target.files.length > 0) {
            const files = fileInput.target.files;
            const fsize = files[0].size;
            const filemb = Math.round((fsize / 1024) / 1024);
            if (filemb <= 10) {
                this.selectedblb = await this.upload(files[0]);
                this.showbigImage = true;
                this.sendImgBtn = true;
            }
        }
    }

    async upload(file: any) {
        return new Promise(done => {
            if (file) {
                // eslint-disable-next-line no-undef
                const reader = new FileReader();
                reader.onload = () => {
                    done(reader.result);
                };
                reader.readAsDataURL(file);
            } else {
                done(null);
            }
        });
    }

    async closeImgModal() {
        this.showbigImage = false;
        this.sendImgBtn = false;
        this.selectedblb = null;
    }

    async sendImmage() {
        const tmp = this.downscaleImage(this.selectedblb, 300);
        this.sendMessage(tmp, false, 'image');
        this.trackForm.controls['text'].setValue('');
        this.showbigImage = false;
        this.selectedblb = null;
    }

    downscaleImage(dataUrl, newWidth, imageType?, imageArguments?) {
        // Provide default values
        imageType = imageType || 'image/jpeg';
        imageArguments = imageArguments || 0.7;

        // Create a temporary image so that we can compute the height of the downscaled image.
        // eslint-disable-next-line no-undef
        const image = new Image();
        image.src = dataUrl;
        const oldWidth = image.width;
        const oldHeight = image.height;
        const newHeight = Math.floor(oldHeight / oldWidth * newWidth)

        // Create a temporary canvas to draw the downscaled image on.
        const canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;

        // Draw the downscaled image on the canvas and return the new data URL.
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, newWidth, newHeight);
        const newDataUrl = canvas.toDataURL(imageType, imageArguments);
        return newDataUrl;
    }
}
